import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/home',
      component: () => import(/* webpackChunkName: "home" */ '../components/common/Home.vue'),
      meta: { title: '主页' },
      redirect: '/dashboard',
      children: [
        {
          path: '/dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/Dashboard.vue'),
          meta: { title: '系统首页' }
        },
        {
          path: '/org',
          component: () => import(/* webpackChunkName: "org" */ '../components/page/org/Org.vue'),
          meta: { title: '组织管理' }
        },
        {
          path: '/project',
          component: () => import(/* webpackChunkName: "project" */ '../components/page/project/Project.vue'),
          meta: { title: '项目管理' }
        },
        {
          path: '/role',
          component: () => import(/* webpackChunkName: "role" */ '../components/page/role/Role.vue'),
          meta: { title: '角色管理' }
        },
        {
          path: '/resource',
          component: () => import(/* webpackChunkName: "resource" */ '../components/page/resource/Resource.vue'),
          meta: { title: '界面资源管理' }
        },
        {
          path: '/user',
          component: () => import(/* webpackChunkName: "user" */ '../components/page/user/User.vue'),
          meta: { title: '用户管理' }
        },


        {
          path: '/dangerType',
          component: () => import(/* webpackChunkName: "dangerType" */ '../components/page/dtype/DangerType.vue'),
          meta: { title: '风险类型' }
        },
        {
          path: '/checkJob',
          component: () => import(/* webpackChunkName: "checkJob" */ '../components/page/job/CheckJob.vue'),
          meta: { title: '巡检作业管理' }
        },
        {
          path: '/pcscheck/mgr',
          component: () => import(/* webpackChunkName: "pcsManage" */ '../components/page/pcscheck/PcsManage.vue'),
          meta: { title: '巡检类型管理' }
        },
        {
          path: '/pcscheck/line',
          component: () => import(/* webpackChunkName: "pcsLines" */ '../components/page/pcscheck/PcsLines.vue'),
          meta: { title: '巡检路线管理' }
        },
        {
          path: '/repair',
          component: () => import(/* webpackChunkName: "repair" */ '../components/page/repair/Repair.vue'),
          meta: { title: '整改列表' }
        },
        {
          path: '/company/check',
          component: () => import(/* webpackChunkName: "checkLogsLv1" */ '../components/page/pcscheck/CheckLogsLv1.vue'),
          meta: { title: '公司级巡检记录' }
        },
        {
          path: '/cj/check',
          component: () => import(/* webpackChunkName: "checkLogsLv2" */ '../components/page/pcscheck/CheckLogsLv2.vue'),
          meta: { title: '车间级巡检记录' }
        },
        {
          path: '/gd/check',
          component: () => import(/* webpackChunkName: "checkLogsLv3" */ '../components/page/pcscheck/CheckLogsLv3.vue'),
          meta: { title: '工段级巡检记录' }
        },
        {
          path: '/bz/check',
          component: () => import(/* webpackChunkName: "checkLogsLv4" */ '../components/page/pcscheck/CheckLogsLv4.vue'),
          meta: { title: '班组级巡检记录' }
        },
        {
          path: '/check/list',
          component: () => import(/* webpackChunkName: "checkList" */ '../components/page/pcscheck/CheckList.vue'),
          meta: { title: '巡检列表' }
        },

        {
          path: '/data/finish/check',
          component: () => import(/* webpackChunkName: "checkFinish" */ '../components/page/report/CheckFinish.vue'),
          meta: { title: '巡检完成度' }
        },
        {
          path: '/data/finish/repair',
          component: () => import(/* webpackChunkName: "repairFinish" */ '../components/page/report/RepairFinish.vue'),
          meta: { title: '整改完成度' }
        },
        {
          path: '/data/danger',
          component: () => import(/* webpackChunkName: "danger" */ '../components/page/report/Danger.vue'),
          meta: { title: '风险类型统计' }
        },
        {
          path: '/data/check/detail',
          component: () => import(/* webpackChunkName: "userCheckDetail" */ '../components/page/report/UserCheckDetail.vue'),
          meta: { title: '巡检详情统计' }
        },
        

        {
          path: '/weixin/user',
          component: () => import(/* webpackChunkName: "wxuser" */ '../components/page/weixin/Wxuser.vue'),
          meta: { title: '微信用户管理' }
        },



        {
          path: '/404',
          component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
          meta: { title: '404' }
        },
        {
          path: '/403',
          component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
          meta: { title: '403' }
        }
      ]
    },
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '../components/page/Login.vue'),
      meta: { title: '登录' }
    },
    {
      path: '/ext/login',
      component: () => import(/* webpackChunkName: "extAccess" */ '../components/page/external/ExtAccess.vue'),
      meta: { title: '系统访问' }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 智能巡检平台`;
  // const role = localStorage.getItem('ms_username');
  let user = localStorage.getItem('token');
  if (!user && to.path !== '/login') {
    if (to.path === '/ext/login') {
      next();
    } else {
      next('/login');
    }
    // next('/login');
    // } else if (to.meta.permission) {
    //     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    //     role === 'admin' ? next() : next('/403');
  } else {
    // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
    if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
      Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
        confirmButtonText: '确定'
      });
    } else {
      next();
    }
  }
});

export default router