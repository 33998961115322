import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import ElementUI from 'element-ui';
import TreeTable from 'tree-table-vue';
import common from './utils/common'
const echarts = require('echarts');
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/css/icon.css';
import globalConfig from './components/common/common.vue'

import axios from 'axios';

// 设置全局配置
Vue.prototype.COMMON_CONFIG = globalConfig
axios.defaults.withCredentials = true
axios.defaults.baseURL = globalConfig.HTTP_API_CTX
Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.prototype.common = common


// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {
  // console.log(config.showLoading == null);
  if (config.showLoading == null || config.showLoading == true) {
    store.state.isLoading = true
  }
  // console.log(config)
  // 为请求头对象，添加token验证的Authorization字段
  //config.headers.Authorization = localStorage.getItem('token')
  if (localStorage.getItem('token')) {
    config.headers['X-Authorization-With'] = localStorage.getItem('token')
  }
  // 在最后必须 return config
  return config
})
// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  if (config.status == 200 && config.data.code == 0) {
    // 返回时插件自动将x-authorization-with转为小写
    store.state.isLoading = false
    if (config.headers['x-authorization-with']) {
      localStorage.setItem('token', config.headers['x-authorization-with']);
    }
  } else if (config.data.code == 401) {
    store.state.isLoading = false
    router.push("/login");
  } else {
    store.state.isLoading = false
  }
  return config
}, error => {
  store.state.isLoading = false
  console.log(error)
})

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: 'small'
});
Vue.use(TreeTable);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

/**权限指令**/
Vue.directive('has', {
  inserted: function (el, binding) {
    if (!Vue.prototype.$_has(binding.value)) {
      el.parentNode.removeChild(el);
    }
  }
});
//权限检查方法
Vue.prototype.$_has = function (value) {
  let isExist = false;
  let buttonpermsStr = localStorage.getItem("user_perms");
  if (buttonpermsStr == undefined || buttonpermsStr == null) {
    return false;
  }
  let buttonperms = buttonpermsStr.split(',');
  for (let i = 0; i < buttonperms.length; i++) {
    if (buttonperms[i].indexOf(value) > -1) {
      isExist = true;
      break;
    }
  }
  return isExist;
};
