<script type="text/javascript">
// const HTTP_HOST = "http://localhost:9001/";
// const HTTP_API_CTX = "http://localhost:9001/api/v1/";
// const WS_HOST = "ws://127.0.0.1:9001/ws/notification/";
// const WS_CHAT_HOST = "ws://127.0.0.1:9001/ws/cloud/chat/";
// const BAIDU_MAP_AK = "B1sFVV9vgRCZEcjy87tyWig7DaITakno";

// const IMG_UPLOAD_URL = "http://localhost:9001/api/v1/file/train/img";
// const LOAD_IMG_URL = "http://localhost:9001/api/v1/file/img?fileName=";



const HTTP_HOST = "http://47.108.203.254:9000/";
const HTTP_API_CTX = "http://47.108.203.254:9000/api/v1/";
const WS_HOST = "ws://47.108.203.254:9000/ws/notification/";
const WS_CHAT_HOST = "ws://47.108.203.254:9000/ws/cloud/chat/";
const BAIDU_MAP_AK = "B1sFVV9vgRCZEcjy87tyWig7DaITakno";

const IMG_UPLOAD_URL = "http://47.108.203.254:9000/api/v1/file/train/img";
const LOAD_IMG_URL = "http://47.108.203.254:9000/api/v1/file/img?fileName=";

// 暴露出这些属性和方法
export default {
  HTTP_HOST,
  HTTP_API_CTX,
  BAIDU_MAP_AK,
  WS_HOST,
  WS_CHAT_HOST,
  IMG_UPLOAD_URL,
  LOAD_IMG_URL,
};
</script>