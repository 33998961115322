export default {
  floatFormat: function (value, fractionDigits) {
    if (value == null) {
      return 0.0;
    }

    return parseFloat(parseFloat(value).toFixed(fractionDigits));
  },
  dateFormat: function (fmt, date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "M+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "m+": date.getMinutes().toString(),         // 分
      "s+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  },
  todayDateStr: function (fmt, date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "M+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": "00",           // 时
      "m+": "00",         // 分
      "s+": "00"          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  },
  tomorrowDateStr: function (fmt, date) {
    let yr = date.getFullYear().toString();
    let mt = (date.getMonth() + 1).toString();
    let dte = (date.getDate() + 1).toString();
    var d = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
    // 获取当月最大天数
    let maxDay = d.getDate();
    if ((date.getDate() + 1) > maxDay) {
      if ((date.getMonth() + 2) > 12) {
        yr = (date.getFullYear() + 1).toString();
        mt = '01';
      } else {
        mt = (date.getMonth() + 2).toString();
      }
      dte = '01';
    }
    let ret;
    const opt = {
      "Y+": yr,        // 年
      "M+": mt,     // 月
      "d+": dte,      // 日
      "H+": "00",           // 时
      "m+": "00",         // 分
      "s+": "00"          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  },
  triggerDate: function (date, range) {
    if (date == null) {
      return true;
    }
    let d1 = date[0].getTime();
    let d2 = date[1].getTime();
    let offset = range * 24 * 60 * 60 * 1000;
    if (d2 - d1 > offset) {
      return false;
    } else {
      return true;
    }
  },
  cameraType: function (type) {
    let result = "";
    switch (type) {
      case 0:
        result = "后车钩";
        break;
      case 1:
        result = "后路况";
        break;
      case 2:
        result = "司机室I";
        break;
      case 3:
        result = "司机室II";
        break;
      case 4:
        result = "机械间";
        break;
      case 5:
        result = "电器间";
        break;
      case 6:
        result = "前路况";
        break;
      case 7:
        result = "前车钩";
        break;
      default:
        result = "未配置";
        break;
    }
    return result;
  },
  getDuration: function (day) {
    var label = ''
    for (let i = 0; i < durationData.length; i++) {
      var d = durationData[i]
      if(d.value === day) {
        label = d.label
      }
    }
    return label
  },
  timeSubtract: function (beginTime, endTime) {
    if (beginTime === null || endTime === null) {
      return
    }
    // console.log(beginTime)
    // console.log(endTime)
    var date1 = beginTime.replace(/-/g, '/')
    var date2 = endTime.replace(/-/g, '/')
    // 转成秒
    var dataCurPar1 = Date.parse(new Date(date1)) / 1000
    var dataCurPar2 = Date.parse(new Date(date2)) / 1000
    
    var offset = dataCurPar2 - dataCurPar1
    var s = offset % 60
    var m = parseInt(offset / 60)
    if (m > 60) {
      var mm = m % 60
      var h = parseInt(offset / 3600)
      return h + '时' + mm + '分' + s + '秒'
    } else {
      return m + '分' + s + '秒'
    }
  }
}


var durationData = [{
  label: '1天',
  value: 1
},
{
  label: '2天',
  value: 2
},
{
  label: '3天',
  value: 3
},
{
  label: '4天',
  value: 4
},
{
  label: '5天',
  value: 5
},
{
  label: '6天',
  value: 6
},
{
  label: '1周',
  value: 7
},
{
  label: '2周',
  value: 14
},
{
  label: '3周',
  value: 21
},
{
  label: '1月',
  value: 30
},
{
  label: '2月',
  value: 60
},
{
  label: '3月',
  value: 90
},
{
  label: '4月',
  value: 120
},
{
  label: '5月',
  value: 150
},
{
  label: '6月',
  value: 180
},
{
  label: '7月',
  value: 210
},
{
  label: '8月',
  value: 240
},
{
  label: '9月',
  value: 270
},
{
  label: '10月',
  value: 300
},
{
  label: '11月',
  value: 330
},
{
  label: '12月',
  value: 360
},
]